import * as React from 'react'
import * as styles from '../components/member-list.module.scss'
import GlobalWrapper from '../components/GlobalWrapper'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/SEO'

const IndexPage = data => {
  const membersData = data.data.allMarkdownRemark.edges
  return (
    <>
      <SEO
        description="これは公立はこだて未来大学における柳研究室20年間の歩みをまとめたアーカイブサイトの研究生一覧ページです。"
        title="MEMBERS"
      />
      <main className={styles.container}>
        <GlobalWrapper>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>MEMBERS</h1>
            <div className={styles.studentItems}>
              {membersData.map(item => (
                <Link
                  to={'/members/' + item.node.frontmatter.key}
                  className={styles.studentItem}
                  key={item.node.frontmatter.key}
                >
                  <GatsbyImage
                    className={styles.studentItemImg}
                    image={getImage(
                      item.node.frontmatter.image.childImageSharp
                        .gatsbyImageData
                    )}
                    alt="research images"
                  />
                  <div className={styles.studentDesc}>
                    <p className={styles.studentName}>
                      {item.node.frontmatter.name}
                    </p>
                    <p className={styles.graduate}>
                      {item.node.frontmatter.graduateYear}年度卒
                    </p>
                  </div>
                </Link>
              ))}
              <div className={styles.studentItem}> </div>
              <div className={styles.studentItem}> </div>
            </div>
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query MyQuerye {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/members/" } }
      sort: { order: ASC, fields: frontmatter___graduateYear }
    ) {
      edges {
        node {
          frontmatter {
            key
            name
            graduateYear
            image {
              childImageSharp {
                gatsbyImageData(width: 100)
              }
            }
          }
        }
      }
    }
  }
`
export default IndexPage
